@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

/*
  Josh's Custom CSS Reset
  https://www.joshwcomeau.com/css/custom-css-reset/
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

#root,
#__next {
  isolation: isolate;
}

body {
  font-family: "Manrope", "Manrope Placeholder", sans-serif;
}

:root {
  --muted-blue: #2a7796;
}

.muted-blue {
  color: var(--muted-blue);
}